<template>
  <div
    :class="['DashboardClientBalanceHistory column', {
      'DashboardClientBalanceHistory--mobile': mobile.isMobile
    }]"
  >
    <div class="DashboardClientBalanceHistory__table column mb-22">
      <div
        class="
        DashboardClientBalanceHistory__mobile-heading
        row row--align-center row--justify-between mt-17 mb-17
      "
      >
        <div class="column column--width-auto">
          <label class="fs-16 fw-med">Current Balance:</label>
          <label class="fs-14 fc-light">A history of all your balances</label>
        </div>

        <label
          :class="['DashboardClientBalanceHistory__balance-flag fs-16', {
            'DashboardClientBalanceHistory__balance-flag--bg-light': clientBalance === 0,
            'DashboardClientBalanceHistory__balance-flag--bg-green': clientBalance > 0,
            'DashboardClientBalanceHistory__balance-flag--bg-red': clientBalance < 0,
          }]"
        >
          {{
            new Intl
              .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
              .format(clientBalance / 100)
          }}
        </label>
      </div>

      <transition name="Transition__fade">
        <base-balance-table
          v-if="balances"
          :balances="balances"
        >
          <div
            v-if="!mobile.isMobile"
            class="Table__slot-content row row--align-center"
          >
            <div class="column">
              <label class="fs-16 fw-med">Balance History</label>
              <label class="fc-light fs-14">Transactions that affect your Bobtail balance</label>
            </div>

            <div class="row row--align-center row--width-auto">
              <v-select
                v-model="balancesFilter"
                @input="getBalances(1)"
                aria-label="Select input to filter balances"
                class="DashboardClientBalanceHistory__balances-filter-select"
                :clearable="false"
                :options="balancesFilterOptions"
                :placeholder="'Filter by:'"
              />
            </div>
          </div>
        </base-balance-table>
      </transition>
    </div>

    <base-pagination
      @paginate="getBalances"
      class="mb-22"
      :item-count="balancesCount"
      :page="balancesPage"
      :request-limit="5"
    />
  </div>
</template>

<script>
// Helpers
import { Balance } from '../../utils/api'
// Components
import BaseBalanceTable from '../../components/base-balance-table.vue'
import BasePagination from '../../components/base-pagination.vue'

export default {
  name: 'DashboardClientBalanceHistory',

  components: {
    BaseBalanceTable,
    BasePagination,
  },

  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    userIsEmployee: {
      type: Boolean,
      required: true,
    },
  },

  async created () {
    this.progressStart()

    await this.getBalances()
  },

  data () {
    return {
      balances: null,
      balancesCount: null,
      balancesFilter: 'None',
      balancesFilterOptions: [
        'None',
        'Non-payment',
        'Shortpay',
        'Overpay',
        'Chargeback',
        'Adjustment',
      ],
      balancesPage: 1
    }
  },

  computed: {
    clientBalance () {
      return this.$store.getters.clientBalance
    },

    mobile () {
      return this.$store.getters.mobile
    },
  },

  methods: {
    balanceReason () {
      if (this.balancesFilter === 'None') return null
      if (this.balancesFilter === 'Non-payment') return 'nonpayment'
      if (this.balancesFilter === 'Shortpay') return 'shortpay'
      if (this.balancesFilter === 'Overpay') return 'overpay'
      if (this.balancesFilter === 'Chargeback') return 'chargeback'
      if (this.balancesFilter === 'Adjustment') return null
    },

    async getBalances (page = null) {
      if (page) this.balancesPage = page

      try {
        const balances = (await Balance.getClientBalances({
          client_id: this.id,
          include: 'clientpayments',
          limit: 5,
          offset: page > 1 ? (page - 1) * 5 : 0,
          reason: this.balanceReason(),
          sort_by: 'accounting',
          sort_direction: 'DESC',
          type: this.balancesFilter === 'Adjustment' ? 'client adjustments' : null,
        })).data
        this.balances = balances.rows.map(balance => Object.assign(balance, { expanded: false }))
        this.balancesCount = balances.count
        this.$emit('update-balance')
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Client Balance History "Get Balances"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'there was an issue getting the client\'s balances' })
      }
    },
  },
}
</script>

<style lang="sass">
.DashboardClientBalanceHistory
  $block: &

  &__balances-filter-select
    width: rem(225px)

    .vs__selected
      &::before
        content: 'Filter by:'
        padding-right: rem(4px)

  &__balance-flag
    border-radius: rem(15px)
    display: inline-block
    line-height: 1
    padding: rem(6px) rem(12px)

    &--bg-green
      background-color: rgba(#34AA44, 0.2)
      color: $success

    &--bg-red
      background-color: #FFEDE9
      color: $danger

    &--bg-light
      background-color: lighten(#A6A8AD, 28)
      color: $text-light

  &__mobile-heading
    display: none
    padding: 0 1rem

  &--mobile
    background-color: $background
    padding: 0 !important // Must override Dashboard--mobile router-view padding

    #{$block}__mobile-heading
      display: flex
</style>