var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "DashboardClientBalanceHistory column",
        {
          "DashboardClientBalanceHistory--mobile": _vm.mobile.isMobile
        }
      ]
    },
    [
      _c(
        "div",
        { staticClass: "DashboardClientBalanceHistory__table column mb-22" },
        [
          _c(
            "div",
            {
              staticClass:
                "\n      DashboardClientBalanceHistory__mobile-heading\n      row row--align-center row--justify-between mt-17 mb-17\n    "
            },
            [
              _vm._m(0),
              _c(
                "label",
                {
                  class: [
                    "DashboardClientBalanceHistory__balance-flag fs-16",
                    {
                      "DashboardClientBalanceHistory__balance-flag--bg-light":
                        _vm.clientBalance === 0,
                      "DashboardClientBalanceHistory__balance-flag--bg-green":
                        _vm.clientBalance > 0,
                      "DashboardClientBalanceHistory__balance-flag--bg-red":
                        _vm.clientBalance < 0
                    }
                  ]
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD"
                        }).format(_vm.clientBalance / 100)
                      ) +
                      "\n      "
                  )
                ]
              )
            ]
          ),
          _c(
            "transition",
            { attrs: { name: "Transition__fade" } },
            [
              _vm.balances
                ? _c(
                    "base-balance-table",
                    { attrs: { balances: _vm.balances } },
                    [
                      !_vm.mobile.isMobile
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "Table__slot-content row row--align-center"
                            },
                            [
                              _c("div", { staticClass: "column" }, [
                                _c("label", { staticClass: "fs-16 fw-med" }, [
                                  _vm._v("Balance History")
                                ]),
                                _c("label", { staticClass: "fc-light fs-14" }, [
                                  _vm._v(
                                    "Transactions that affect your Bobtail balance"
                                  )
                                ])
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row row--align-center row--width-auto"
                                },
                                [
                                  _c("v-select", {
                                    staticClass:
                                      "DashboardClientBalanceHistory__balances-filter-select",
                                    attrs: {
                                      "aria-label":
                                        "Select input to filter balances",
                                      clearable: false,
                                      options: _vm.balancesFilterOptions,
                                      placeholder: "Filter by:"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.getBalances(1)
                                      }
                                    },
                                    model: {
                                      value: _vm.balancesFilter,
                                      callback: function($$v) {
                                        _vm.balancesFilter = $$v
                                      },
                                      expression: "balancesFilter"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("base-pagination", {
        staticClass: "mb-22",
        attrs: {
          "item-count": _vm.balancesCount,
          page: _vm.balancesPage,
          "request-limit": 5
        },
        on: { paginate: _vm.getBalances }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column column--width-auto" }, [
      _c("label", { staticClass: "fs-16 fw-med" }, [
        _vm._v("Current Balance:")
      ]),
      _c("label", { staticClass: "fs-14 fc-light" }, [
        _vm._v("A history of all your balances")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }